import React, { useState, useEffect } from "react";
import '../../CircularGraphHours/CircularGraphHours.css';
import { GetYoutubeData } from "../../../../GatherUserInformationDB/GatherDatabaseUser";
import { GenerateCellStyle } from "../OvertimeAnalyseTable";
import CreateTable from "../TableClass";
import ChartModule from "../../../chartMaker/ChartModule";

const YoutubeDailyDataTable = () => 
{       
    const [rowTableGeneral, setRowTableGenerale] = useState([]);
    const [colTableGeneral, setColTableGeneral] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [graphLabel, setGraphLabel] = useState(["comments", "shares", "likes", "dislikes", "subscribers_gained", "subscribers_lost"]);

    const GetDatas = async() => 
    {    
        let allRow = [];
        let finalDataCol = [];
        finalDataCol.push({ field: 'id' });

        // Fetch YouTube data
        let compData = await GetYoutubeData();
        compData = compData.youtube_channelInsight.data.daily_data;

        // Define columns for the table
        finalDataCol.push({ field: "date", cellStyle: GenerateCellStyle() });
        finalDataCol.push({ field: "comments", cellStyle: GenerateCellStyle() });
        finalDataCol.push({ field: "shares", cellStyle: GenerateCellStyle() });
        finalDataCol.push({ field: "likes", cellStyle: GenerateCellStyle() });
        finalDataCol.push({ field: "dislikes", cellStyle: GenerateCellStyle() });
        finalDataCol.push({ field: "subscribers_gained", cellStyle: GenerateCellStyle() });
        finalDataCol.push({ field: "subscribers_lost", cellStyle: GenerateCellStyle() });

        let graphDataFormatted = [];

        // Iterate over fetched data
        compData.forEach((list, index) => 
        {
            let finalDataRow = {};
            finalDataRow['id'] = index;
            finalDataRow['date'] = list['date'];
            finalDataRow['comments'] = list['comments'];
            finalDataRow['shares'] = list['shares'];
            finalDataRow['likes'] = list['likes'];
            finalDataRow['dislikes'] = list['dislikes'];
            finalDataRow['subscribers_gained'] = list['subscribers_gained'];
            finalDataRow['subscribers_lost'] = list['subscribers_lost'];
        
            allRow.push(finalDataRow);

            // Push to graphDataFormatted for charting
            graphDataFormatted.push({
                date: list['date'],
                comments: list['comments'],
                shares: list['shares'],
                likes: list['likes'],
                dislikes: list['dislikes'],
                subscribers_gained: list['subscribers_gained'],
                subscribers_lost: list['subscribers_lost']
            });
        });

        setColTableGeneral(finalDataCol); // Set columns for the table
        setRowTableGenerale(allRow);      // Set rows for the table
        setGraphData(graphDataFormatted); // Set data for the chart
    };

    useEffect(() => 
    {
        GetDatas();
    }, []);

    return (
        <div>
            <h1>YouTube General Data</h1>
            <CreateTable col={colTableGeneral} row={rowTableGeneral} />
            <ChartModule
                chartTitle="YouTube Interaction Data Overview"
                dataSets={[graphData]}    // Use the fetched graph data
                chartType="line"
                xField="date"
                yFields={graphLabel}      // Label fields for Y-axis
                theme="ag-pastel"
                height={500}
            />
        </div>
    );
};

export default YoutubeDailyDataTable;
