import React, { useState } from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

function PdfCreator({ data, generalData }) 
{
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => 
    {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const generatePdf = async () => 
        {
            const doc = new jsPDF("p", "pt", "a4");
            const itemsPerPage = 6;
            const pageHeight = doc.internal.pageSize.height;
            const pageWidth = doc.internal.pageSize.width;
    
            // Grouping data by network
            const groupedData = data.reduce((acc, item) => 
            {
                if (!acc[item.network]) 
                {
                    acc[item.network] = [];
                }
                acc[item.network].push(item);
                return acc;
            }, {});
    
            let currentPage = 1;
            let currentY = 60;
    
            // Cover Page with Summary Statistics
            const addCoverAndSummaryPage = () => 
            {
                doc.setFont("helvetica", "bold");
                doc.setFontSize(26);
                doc.setTextColor(35, 55, 77);
                doc.text(generalData.compName, pageWidth / 2, 80, { align: "center" });
                
                doc.setFontSize(20);
                doc.text("Data Analysis Report", pageWidth / 2, 120, { align: "center" });
                
                doc.setFontSize(12);
                doc.setTextColor(0);
                doc.text(`Date: ${new Date().toLocaleDateString()}`, pageWidth / 2, 140, { align: "center" });
                
                let totalItems = 0;
                let totalChange = 0;
                
                Object.values(groupedData).forEach(entries => 
                {
                    totalItems += entries.length;
                    entries.forEach(item => 
                    {
                        totalChange += item.currentStat - item.previousStat;
                    });
                });
                
                const avgChange = totalChange / totalItems;
                
                doc.setFontSize(14);
                doc.setTextColor(35, 55, 77);
                doc.text("Summary Statistics", pageWidth / 2, 180, { align: "center" });
                
                doc.setFontSize(12);
                doc.setTextColor(0);
                doc.text(`Total items analyzed: ${totalItems}`, pageWidth / 2, 200, { align: "center" });
                doc.text(`Average change across all networks: ${avgChange.toFixed(2)}`, pageWidth / 2, 220, { align: "center" });
                
                const networkList = Object.keys(groupedData).join(", ");
                const splitText = doc.splitTextToSize(`Networks Analyzed: ${networkList}`, pageWidth - 80);
                doc.text(splitText, 40, 250);
                
                doc.addPage();
                currentPage++;
                currentY = 60;
            };
            
            const addTableOfContents = () => 
            {
                doc.setFontSize(16);
                doc.setTextColor(35, 55, 77);
                doc.text("Table of Contents", pageWidth / 2, 40, { align: "center" });
                
                doc.setFontSize(12);
                doc.setTextColor(0);
        
                let tocY = 70;
                Object.keys(groupedData).forEach((network, index) => 
                {
                    if (tocY >= pageHeight - 40) {
                        doc.addPage();
                        tocY = 40;
                    }
                    doc.text(`${index + 1}. ${network} Data`, 40, tocY);
                    tocY += 20;
                });
        
                doc.addPage();
                currentPage++;
                currentY = 60;
            };
            
            const addHeader = () => 
            {
                doc.setFont("helvetica", "bold");
                doc.setFontSize(14);
                doc.setTextColor(35, 55, 77);
                doc.text("Data Report", pageWidth / 2, 40, { align: "center" });
    
                doc.setFontSize(10);
                doc.setTextColor(0);
                doc.text(generalData.compName, 40, 40);
    
                doc.setDrawColor(150);
                doc.line(20, 50, pageWidth - 20, 50);
    
                if (generalData.logo) 
                {
                    doc.addImage(generalData.logo, "JPEG", 15, 30, 40, 40);
                }
            };
    
            const addDataEntryTable = (network, entries) => 
            {
                const tableData = entries.map(item => {
                    const comparison = item.currentStat - item.previousStat;
                    const comparisonText = comparison > 0 ? `+${comparison}` : `${comparison}`;
                    const comparisonColor = comparison > 0 ? [0, 153, 0] : [204, 0, 0];
    
                    return [
                        item.name,
                        item.currentStat,
                        { content: `${item.previousStat} (${comparisonText})`, styles: { textColor: comparisonColor } }
                    ];
                });
    
                doc.autoTable({
                    head: [['Name', 'Current Stat', 'Previous Stat (Comparison)']],
                    body: tableData,
                    startY: currentY,
                    theme: 'striped',
                    styles: { fontSize: 10 },
                    headStyles: { fillColor: [35, 55, 77] },
                    margin: { top: 10, left: 20, right: 20 },
                    didDrawPage: (data) => {
                        addFooter(doc, currentPage);
                        currentPage++;
                        currentY = data.cursor.y + 10;
                    }
                });
            };
    
            const addFooter = (doc, pageNumber) => 
            {
                const pageHeight = doc.internal.pageSize.height;
                const pageWidth = doc.internal.pageSize.width;
    
                doc.setFontSize(10);
                doc.setTextColor(150);
                doc.text(generalData.compName, 20, pageHeight - 20);
                doc.text(`Page ${pageNumber}`, pageWidth - 40, pageHeight - 20);
            };
    
            addCoverAndSummaryPage();
            addTableOfContents();
            addHeader();
    
            for (const network in groupedData) 
            {
                doc.setFontSize(14);
                doc.setTextColor(35, 55, 77);
                doc.setDrawColor(35, 55, 77);
                doc.rect(20, currentY, pageWidth - 40, 20);
                doc.text(`${network} Data`, 30, currentY + 15);
                currentY += 30;
                
                addDataEntryTable(network, groupedData[network]);
    
                if (currentY > pageHeight - 60) 
                {
                    doc.addPage();
                    currentPage++;
                    currentY = 60;
                    addHeader();
                }
            }
    
            addFooter(doc, currentPage);
    
            const pdfBlob = doc.output("blob");
            const url = URL.createObjectURL(pdfBlob);
            window.open(url);
        };

    return (
        <>
            <button className="button" onClick={toggleSidebar}>
                PDF
            </button>

            <div style={{ 
                ...styles.sidebar, 
                transform: isSidebarOpen ? "translateX(0)" : "translateX(100%)" 
            }}>
                <button style={styles.closeButton} onClick={toggleSidebar}>×</button>
                <h1 style={styles.title}>PDF generator</h1>
                <h2>Generate a report for the last month</h2>
                <button style={styles.generateButton} onClick={generatePdf}>
                    Generate PDF
                </button>
            </div>
        </>
    );
}

const styles = {
    sidebar: {
        position: 'fixed',
        top: '0',
        right: '0',
        height: '100%',
        width: '300px',
        backgroundColor: '#f9f9f9',
        borderLeft: '1px solid #ddd',
        padding: '20px',
        zIndex: '1000',
        boxShadow: '-4px 0 8px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.3s ease',
    },
    closeButton: {
        backgroundColor: 'transparent',
        border: 'none',
        fontSize: '24px',
        cursor: 'pointer',
        color: '#aaa',
        position: 'absolute',
        top: '15px',
        right: '15px',
    },
    title: {
        fontSize: '20px',
        color: '#23374d',
        fontWeight: '600',
        marginTop: '40px',
        textAlign: 'center',
    },
    generateButton: {
        backgroundColor: '#23374d',
        color: 'white',
        padding: '12px 25px',
        fontSize: '16px',
        fontWeight: 'bold',
        cursor: 'pointer',
        borderRadius: '6px',
        width: '100%',
        textAlign: 'center',
        border: 'none',
        marginTop: '20px',
    }
};

export default PdfCreator;
