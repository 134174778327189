import React, { useEffect, useRef } from "react";
import { AgCharts } from 'ag-charts-community';

const ChartModule = ({ chartTitle, dataSets, chartType = 'line', xField, yFields, theme = 'ag-default', height = 400 }) => 
{
    const chartRef = useRef(null); // Reference to the chart DOM element

    useEffect(() => 
    {
        if (chartRef.current && dataSets && dataSets.length > 0) 
        {
            // Create a single series for each dataset based on the yFields provided
            const chartSeries = yFields.map((field) => 
            {
                return {
                    type: chartType,
                    xKey: xField,
                    yKey: field,        // Associate yField with each series
                    yName: field,       // Label each series in the legend
                    stroke: getRandomColor(),
                    marker: {
                        enabled: true, // Show markers for better visualization
                    }
                };
            });

            // Combine all datasets into one for proper chart rendering
            const combinedData = mergeDataSets(dataSets, xField);

            // Initialize or update the chart
            const chartOptions = {
                theme: { baseTheme: theme },
                container: chartRef.current,
                autoSize: true,
                title: { text: chartTitle, fontSize: 18 },
                data: combinedData,  // Use merged data
                series: chartSeries, // Create series from multiple datasets
                axes: [
                    {
                        type: 'category',
                        position: 'bottom',
                        title: { text: xField },
                    },
                    {
                        type: 'number',
                        position: 'left',
                        title: { text: 'Values' },
                    }
                ],
                legend: {
                    position: 'bottom',
                    spacing: 20,
                },
            };

            // Create or update chart
            AgCharts.create(chartOptions);

            // Cleanup chart on component unmount
            return () => {
                if (chartRef.current) 
                {
                    chartRef.current.innerHTML = ""; // Clear the chart content
                }
            };
        }
    }, [dataSets, chartType, xField, yFields, theme]);

    // Function to generate random colors
    const getRandomColor = () => 
    {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) 
        {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    // Function to merge multiple datasets based on the xField
    const mergeDataSets = (dataSets, xField) => 
    {
        let mergedData = [];
        dataSets.forEach(dataSet => 
        {
            dataSet.forEach(item => 
            {
                // Check if the xField already exists in mergedData
                let existingItem = mergedData.find(data => data[xField] === item[xField]);
                if (existingItem) 
                {
                    // Add new fields to the existing entry
                    Object.assign(existingItem, item);
                } 
                else 
                {
                    // Create a new entry
                    mergedData.push({ ...item });
                }
            });
        });
        return mergedData;
    };

    return (
        <div style={{ height: `${height}px`, width: '100%' }}>
            <div ref={chartRef} style={{ height: '100%', width: '100%' }}></div>
        </div>
    );
};

export default ChartModule;
