import React from "react";
import { useState, useEffect} from "react";
import '../CircularGraphHours/CircularGraphHours.css';
import { GetUserCompanyData } from "../../../GatherUserInformationDB/GatherDatabaseUser";
import { GenerateCellStyle } from "./OvertimeAnalyseTable";
import CreateTable from "./TableClass";
import ChartModule from "../../chartMaker/ChartModule";

const GeneraleDataTable = () =>
{        const [rowTableGeneral, setRowTableGenerale] = useState([]);
    const [colTableGeneral, setColTableGeneral] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [graphLabel, setGraphLabel] = useState(["impressions", "profilVisit", "websiteVisit", "emailUse", "men", "women"]);

    const GetDatas = async() =>
    {    
        let allRow = [];
        let finalDataCol = [];
        finalDataCol.push({field: 'id'})
        
        finalDataCol.push({
            field: 'instagram_insta_impressions',
            cellStyle: GenerateCellStyle()
        });

        finalDataCol.push({field: "instagram_gender_men", cellStyle: GenerateCellStyle()})
        finalDataCol.push({field: "instagram_gender_women", cellStyle: GenerateCellStyle()})
        finalDataCol.push({field: "instagram_profil_visit", cellStyle: GenerateCellStyle()})
        finalDataCol.push({field: "instagram_website_visit", cellStyle: GenerateCellStyle()})
        finalDataCol.push({field: "instagram_email_use", cellStyle: GenerateCellStyle()})
        let graphDataFormatted = [];

        const compData = await GetUserCompanyData();
        compData.forEach((list, index) =>
        {
            let finalDataRow = {};
            finalDataRow['id'] = index;
            finalDataRow['instagram_insta_impressions'] = list['instagram_insta_impressions'];
            finalDataRow['instagram_profil_visit'] = list['instagram_profil_visit'];
            finalDataRow['instagram_website_visit'] = list['instagram_website_visit'];
            finalDataRow['instagram_email_use'] = list['instagram_email_use'];
            finalDataRow['instagram_gender_men'] = list['instagram_gender'][0]['value'];
            finalDataRow['instagram_gender_women'] = list['instagram_gender'][1]['value'];

            allRow.push(finalDataRow);
            graphDataFormatted.push({
                id: index,
                impressions: list['instagram_insta_impressions'],
                profilVisit: list['instagram_profil_visit'],
                websiteVisit: list['instagram_website_visit'],
                emailUse: list['instagram_email_use'],
                men: list['instagram_gender'][0]['value'],
                women: list['instagram_gender'][1]['value']
            });
        });

        setColTableGeneral(finalDataCol);
        setRowTableGenerale(allRow);
        setGraphData(graphDataFormatted);
    }

    useEffect(() => 
    {
        GetDatas();
    }, []);
    return (
        <div>
            <h1>General data</h1>
            <CreateTable col= {colTableGeneral} row={rowTableGeneral}/>
            <ChartModule
                chartTitle="Instagram General Data Overview"
                dataSets={[graphData]}    // Use the fetched graph data
                chartType="line"
                xField="id"
                yFields={graphLabel}      // Label fields for Y-axis
                theme="ag-pastel"
                height={500}
            />
    </div>
    );
}

export default GeneraleDataTable;