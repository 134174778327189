import React from "react";
import Cookies from "js-cookie";
import "./Deconnect.css";

const DeconnectUser = () =>
{

    const DeconnectAll = () =>
    {
        Cookies.remove('authPulseUsername');
        Cookies.remove('authPulsePassword');
        Cookies.remove('sessionAccountValid');

        window.location.href = '/';
    }

    return (
        <button id="deconnectButt" onClick={DeconnectAll}>deconnect</button>
    );
}

export default DeconnectUser;