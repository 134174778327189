import React from 'react';
import './Features.css';

const FeatureItem = ({ title, descriptionSmall, onHover, onLeave }) => 
{
    return (
        <div 
            className="feature-item" 
            onMouseEnter={onHover}
            onMouseLeave={onLeave}
        >
            <h3>{title}</h3>
            <p>{descriptionSmall}</p>
        </div>
    );
}

export default FeatureItem;
