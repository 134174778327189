import React, { useState, useEffect } from 'react';
import './DataGenerator.css';
import Checkbox from './DataGeneratorComponent/Checkbox';
import { hostLink } from '../../DatabaseCommunication/GetDataFromUser';
import Cookies from "js-cookie";
import ChannelInsights from './YoutubeGenerator';
import {GetCompanyId, GetUserDatas} from "../../GatherUserInformationDB/GatherDatabaseUser.js";
import LoadingModule from '../../loading/loading.js';



const DataGenerator = () => 
    {           
        const [makeIsGenerating, setGenerating] = useState(false);
   
        async function GenerateData()
        {
        const userData = await GetUserDatas();

        const tokens = await fetch(hostLink + "/get_token/" + userData[4]);
        const tokensContent = await tokens.json();
        setGenerating(true);

        if(!tokensContent["access_token"])
        {
            window.location.href = hostLink + '/authorize?state=' + (JSON.stringify({ email: userData[4] }));
        }
        const pythonOutput = document.getElementById('python-output');
        const idcompJson = await GetCompanyId();
        const jsonizeId = await idcompJson.json();
        console.log(jsonizeId);
        let dataToSave = {};
        // Retrieve the JSON string from cookies and parse it back into an object
        if (Cookies.get("userNetworkData"))
        {
            dataToSave = JSON.parse(Cookies.get("userNetworkData"));
        }
        console.log(dataToSave);
        fetch(hostLink + '/GenerateDataNetwork', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                networksIdentifiant: dataToSave,
                idcomp: jsonizeId.id,
                access_token: tokensContent["access_token"],
                refresh_token: tokensContent['refresh_token'],
                token_uri: tokensContent['token_uri'],
                client_id: tokensContent['client_id'],
                client_secret: tokensContent['client_secret']
            })
        })
        .then(response => response.json())
        .then(data => {
            console.log("Response from server:", data);
            pythonOutput.innerHTML = '';
            setGenerating(false);   
            // Loop through each network in message
            for (const [networkName, networkInfo] of Object.entries(data.message))
            {
                const newDiv = document.createElement("div");
                const status = networkInfo['status']; 
                newDiv.textContent = `${networkName} : ${status == 200 ? 'Ok !' : 'error code ' + status}`;
                newDiv.style.color = status == 200 ? 'green' : 'red';
                newDiv.style.fontWeight = 'bold';
                pythonOutput.appendChild(newDiv);
            }
        })
        }
    
        function getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            console.log(value);
        
            if (parts.length === 2) return parts.pop().split(';').shift();
        }


        return (
            <div className="data-generator-container">
            {makeIsGenerating && (<LoadingModule text="Generating Data..."/>)}
                <h1>Data Generation</h1>
                <hr />
                <div id="data-generator-horizontalBox">
                    <p>
                        For some data, such as Instagram metrics, we require manual generation each month. <br/>
                        This process enables accurate tracking and analysis, as we cannot retrieve data from
                        prior periods without first initiating a manual generation.
                    </p>
                </div>
                <div className="data-generator-subcontainer">
                    <button type="button" id="data-generator-button" onClick={GenerateData}>
                        Generate Monthly Data
                    </button>
                    <p id="python-output">Information about the generation will appear here once complete.</p>
                </div>
            </div>
        );
        
    };
    

    export default DataGenerator;