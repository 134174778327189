import React, { useState } from 'react';
import RoadMap from '../../EchowaveLandingPage/components/Roadmap';
import './BetaAccessModal.css';

const BetaAccessModal = () => 
{
    const [isOpen, setIsOpen] = useState(true);
    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={() => setIsOpen(false)}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="modal-left-border"></div>
                <button className="close-button" onClick={() => setIsOpen(false)}>X</button>
                
                <div className="modal-header">
                    {/* Image column */}
                    <div className="modal-image-column">
                        <img src="https://img.freepik.com/free-vector/design-tools-concept-illustration_114360-1069.jpg?t=st=1730665445~exp=1730669045~hmac=4645a78ea0cd96ba7496c4c60f63d8df9cb57785b15cc6026ba13e05d5350426&w=826" alt="Beta Access" className="modal-image" />
                        <img src="https://img.freepik.com/free-vector/writing-illustration-paper-concept-illustration_114360-8148.jpg?t=st=1730665429~exp=1730669029~hmac=c0cdca793c4a316ec9fe0d874f575fb83862fb5ef3fde6edf87234976014d41c&w=826" alt="Beta Access" className="modal-image" />
                    </div>

                    {/* Scrollable content */}
                    <div className="modal-scrollable-content">
                        <h2>Beta Access Information</h2>
                        <p className="modal-description">
                            Welcome to the beta of Echowave! You have access to everything. Here is a roadmap for the upcoming months:
                        </p>
                        <RoadMap />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BetaAccessModal;
