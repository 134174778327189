import React from "react";
import "./term of service.css";
import Footer from "../../EchowaveLandingPage/components/Footer";
import Header from "../../EchowaveLandingPage/components/Header";

const TermOfService = () =>
    {
        return (
            <section>
                <Header/>
                <div className="privacy-policy-container">
    <header>
        <h1>Terms of Service</h1>
        <p><strong>Effective Date: 1/10/2024</strong></p>
    </header>
    
    <div class="terms-container">
        <h2>1. Acceptance of Terms</h2>
        <p>By accessing or using our services, you confirm that you have read, understood, and agree to these Terms. If you do not agree with any part of these Terms, you must not use our services.</p>

        <h2>2. Services Provided</h2>
        <p>Pulse Software offers application development services and related functionalities through our App and website. We may also provide updates, enhancements, or new features as part of our services.</p>

        <h2>3. User Accounts</h2>
        <p>To access certain features of our services, you may need to create an account. You agree to provide accurate, complete, and up-to-date information during the registration process and to keep your account information current. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>

        <h2>4. User Responsibilities</h2>
        <p>You agree to use our services in compliance with all applicable laws and regulations. You are prohibited from:</p>
        <ul>
            <li>Engaging in any unauthorized use of our services.</li>
            <li>Transmitting any harmful or illegal content.</li>
            <li>Attempting to interfere with the security or integrity of our services.</li>
            <li>Collecting personal information from other users without their consent.</li>
        </ul>

        <h2>5. Payment Terms</h2>
        <p>If you purchase any services or subscriptions, you agree to pay all applicable fees. We reserve the right to change our pricing at any time, but we will notify you of any changes in advance.</p>

        <h2>6. Intellectual Property Rights</h2>
        <p>All content, trademarks, and other intellectual property rights associated with our services are owned by or licensed to Pulse Software. You may not reproduce, distribute, modify, or create derivative works of any content without our express written consent.</p>

        <h2>7. Termination</h2>
        <p>We may suspend or terminate your access to our services at any time, with or without notice, if we believe you have violated these Terms or engaged in any conduct that may harm us or other users.</p>

        <h2>8. Limitation of Liability</h2>
        <p>To the fullest extent permitted by law, Pulse Software shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including loss of profits, arising from your use of or inability to use our services.</p>

        <h2>9. Indemnification</h2>
        <p>You agree to indemnify and hold harmless Pulse Software, its affiliates, and their respective officers, directors, employees, and agents from any claims, losses, liabilities, damages, costs, or expenses arising out of or related to your use of our services or violation of these Terms.</p>

        <h2>10. Changes to Terms</h2>
        <p>We reserve the right to modify these Terms at any time. Any changes will be posted on this page, and we will notify you via email or other communication methods if required. Your continued use of our services after any changes constitutes your acceptance of the revised Terms.</p>
        
        <h2>11. Contact Us</h2>
        <p>If you have any questions about these Terms, please contact us at:</p>
        <p><strong>Pulse Software</strong><br />
        Email: dotsoft.pro@gmail.com<br /></p>
    </div>
    </div>
    <Footer/>
</section>
        );
    }
    

export default TermOfService;