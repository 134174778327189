import React, { useState, useEffect } from 'react';
import './Network.css';
import DataGenerator from './DataGenerator';
import SideMenu from '../SideBar/sideBar';
import Team from '../../Sidebar/Team.js';
import Notifications from '../../Sidebar/Notification.js';
import CircularGraphHours from './CircularGraphHours/CircularGraphHours.js';
import OvertimeAnalyseTable from './Table/OvertimeAnalyseTable.js';
import OvertimeAnalyseTableYoutube from './Table/YoutubeTable/OvertimeAnalyseTableYoutube.js';
import PdfCreator from './PdfCreator.js';
import { GetUserCompanyData } from '../../GatherUserInformationDB/GatherDatabaseUser.js';
import ChartToPDF from './tableTest.js';
import { GetActualUserId, hostLink } from '../../DatabaseCommunication/GetDataFromUser.js';
import { useNavigate } from 'react-router-dom';
import LoadingModule from '../../loading/loading.js';

const Networks = {
    INSTAGRAM: 'instagram',
    YOUTUBE: 'youtube',
    TIKTOK: 'tiktok'
};


const NetworkPage = () =>
{
    const navigate = useNavigate(); // Hook for navigation

    const [choosedNetwork, SetChoosedNetwork] = useState(Networks.INSTAGRAM);
    const [isLoading, SetIsLoading] = useState(true);
    const [sampleData, SetSampleData] = useState([]);
    const generalData = {
        compName : "Your company"
    };

    const DataForPdfGenerator = async () => 
        {
            let finalDatas = [];
            const compData = await GetUserCompanyData();
            console.log(compData);
            const recentList = compData[compData.length - 1];
            const lastList = compData.length - 2 >= 0 ? compData[compData.length - 2] : null;
        
            // Helper function to push data into finalDatas
            const addStat = (network, name, currentStat, previousStat) => {
                finalDatas.push({
                    network: network,
                    name: name,
                    currentStat: currentStat === null ? 0 : currentStat,
                    previousStat: previousStat === null ? 0 : previousStat
                });
            };
        
            // Predefined stat groups
            const predefinedStats = [
                { network: "instagram", name: "impressions", key: "instagram_insta_impressions" },
                { network: "instagram", name: "profil visit", key: "instagram_profil_visit" },
                { network: "instagram", name: "website visit", key: "instagram_website_visit" },
                { network: "instagram", name: "email use", key: "instagram_email_use" },
                { network: "instagram", name: "men amount", key: "instagram_gender", genderIndex: 0 },
                { network: "instagram", name: "women amount", key: "instagram_gender", genderIndex: 1 }
            ];
        
            // Add predefined stats
            predefinedStats.forEach(stat => {
                if (stat.genderIndex !== undefined) {
                    addStat(
                        stat.network,
                        stat.name,
                        recentList[stat.key][stat.genderIndex]['value'],
                        lastList === null ? 0 : lastList[stat.key][stat.genderIndex]['value']
                    );
                } else {
                    addStat(
                        stat.network,
                        stat.name,
                        recentList[stat.key],
                        lastList === null ? 0 : lastList[stat.key]
                    );
                }
            });
        
            // Helper function for lists like men, women, countries, and cities
            const addListStats = (network, recentDataList, lastDataList) => {
                recentDataList.forEach((element, index) => {
                    addStat(
                        network,
                        element["label"],
                        element["value"],
                        lastDataList === null ? 0 : lastDataList[index]["value"]
                    );
                });
            };
        
            // Add men, women, countries, and cities data
            addListStats("instagram men", recentList["instagram_men"], lastList ? lastList["instagram_men"] : null);
            addListStats("instagram women", recentList["instagram_women"], lastList ? lastList["instagram_women"] : null);
            addListStats("instagram country", recentList["instagram_top_countries"], lastList ? lastList["instagram_top_countries"] : null);
            addListStats("instagram city", recentList["instagram_top_cities"], lastList ? lastList["instagram_top_cities"] : null);
        
            // Add product stats
            const productStats = [
                { name: "click", key: "instagram_product_click" },
                { name: "save", key: "instagram_product_save" },
                { name: "view", key: "instagram_product_view" }
            ];
        
            productStats.forEach(stat => {
                addStat(
                    "instagram product",
                    stat.name,
                    recentList[stat.key] === null ? 0 : recentList[stat.key],
                    lastList === null ? 0 : lastList[stat.key] === null ? 0 : lastList[stat.key]
                );
            });
        
            const youtubePublicData = [
                {name : "subscriber", key: "youtube_subscriber"},
                {name: "video count", key:"youtube_videoCount"},
                {name: "view count", key:"youtube_viewCount"}
            ]
        
            youtubePublicData.forEach(stat => {
                addStat(
                    "youtube public",
                    stat.name,
                    recentList[stat.key] === null ? 0 : recentList[stat.key],
                    lastList === null ? 0 : lastList[stat.key] === null ? 0 : lastList[stat.key]
                );
            });
        
            // Update the state
            SetSampleData(finalDatas);
        };
        
    
    const ChangeStatsNetworkType = (network = 'instagram') => 
        {
            console.log(`Displaying stats for ${network}`);
            SetChoosedNetwork(network);
        };

        useEffect(() => {
            DataForPdfGenerator();
        }, [])


        


    const isUserAvailable = async () =>
    {
        const userId = await GetActualUserId();

        const hasaccess = await fetch(hostLink + "/GetUserIsSubEchowave", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({customer_id : userId}),
        });
        const finalResult = await hasaccess.json();
        console.log(finalResult);
        if (finalResult.hasaccess == false)
        {
            navigate("/Account");
        }
    }

    useEffect(() => {
        isUserAvailable();
    }, [])


    return (
        <div className="containerAllNetworks">
            <div className="access-bar-content">
            <PdfCreator data={sampleData} generalData={generalData}/>
                <Notifications />
                <Team />
            </div>
            <SideMenu/>
            <DataGenerator/>
            
{/* 
            <div className="containerNetwork" id="oneBigContainer">
                <div id="horizontalBox">
                    <button onClick={() => ChangeStatsNetworkType()}>Instagram</button>
                    <button onClick={() => ChangeStatsNetworkType(Networks.YOUTUBE)}>YouTube</button>
                    <button onClick={() => ChangeStatsNetworkType(Networks.TIKTOK)}>TikTok</button>
                </div>
            </div> */}

            <CircularGraphHours/>
            <OvertimeAnalyseTable/>
            <OvertimeAnalyseTableYoutube/>
            
        </div>
    );
};

export default NetworkPage;
