import React, { useEffect, useState } from 'react';
import './EchowavePopup.css';
import { Link } from 'react-router-dom';

function EchowavePopup() {


    return (
            <div className="echowave-popup">
                <h2>Exclusive Offer for Beta Testers!</h2>
                <p className="price">
                    <span className="discounted-price">51.70€/month </span>
                    <span className="original-price">103.40€/month</span> 
                </p>
                <p className="highlight">50% Off for Beta Testers!</p>
                <p>Plus, enjoy a 1-month free trial!</p>
                    <Link to="/getaccessechowave">
                        <button className="cta-button">Join us</button>
                    </Link>
            </div>
    );
}

export default EchowavePopup;
