import React, { useState, useEffect } from "react";
import '../../CircularGraphHours/CircularGraphHours.css';
import YoutubeGeneralDataTable from "./YoutubeGeneralDataTable";
import { GetUserCompanyData, GetYoutubeData } from "../../../../GatherUserInformationDB/GatherDatabaseUser";
import './youtube.css';
import YoutubeDailyDataTable from "./YoutubeDailyData";
import YoutubeViewDataTable from "./YoutubeViewData";
import ChartModule from "../../../chartMaker/ChartModule";
import LoadingSpinner from "../../../../loading/LoadingSpinner";


const OvertimeAnalyseTableYoutube = () => 
{
    const [activeTable, setActiveTable] = useState('general'); // State to track the active table
    const [companyData, setCompanyData] = useState(null); // State to store the fetched data


    const data1 = [
        { date: '2023-01-01', valueA: 50, valueB: 100 },
        { date: '2023-02-01', valueA: 75, valueB: 90 },
        { date: '2023-03-01', valueA: 100, valueB: 80 },
        { date: '2023-04-01', valueA: 120, valueB: 110 },
    ];

    const data2 = [
        { date: '2023-01-01', valueA: 500, valueB: 100 },
        { date: '2023-02-01', valueA: 705, valueB: 90 },
        { date: '2023-03-01', valueA: 1000, valueB: 80 },
        { date: '2023-04-01', valueA: 1020, valueB: 110 },
    ];


    const renderTable = () => {
        switch (activeTable) {
            case 'general':
                return <YoutubeGeneralDataTable />;
            case 'Specific data':
                return <YoutubeDailyDataTable />
            case 'viewData':
                return <YoutubeViewDataTable />
            default:
                return <YoutubeGeneralDataTable />;
        }
    };

    const GetCompanyData = async() =>
    {        
        const compData = await GetYoutubeData();

        setCompanyData(compData);
    }
    useEffect(() => 
        {
            GetCompanyData();
        }, []); // Empty dependency array ensures this runs once on component mount
    
    const DisplayDatas = () =>
    {

    }
    const getTotalMonths = (startDate, endDate) => 
        {
            const start = new Date(startDate);
            const end = new Date(endDate);
            const months = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());
            return months;
        }
        
        // Function to format the date (e.g., "January 2024")
        const formatDate = (dateStr) => 
        {
            const date = new Date(dateStr);
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return date.toLocaleDateString(undefined, options);
        }


    return (
        <div className="containerNetwork">
            <h1>What is your evolution over time on Youtube?</h1>
            <hr />
            <h3>Here is all your data in a table over time.</h3>

            { <div>
                {companyData ? (
                    <div className="youtubeChannelInsights grid">
    <div className="insight-card period-card">
        <i className="icon icon-calendar"></i>
        <p>Channel Insights Period</p>
        <span className="highlight">
            Total Duration: {getTotalMonths(companyData["youtube_channelInsight"]["data"]["daily_data"][0]["date"], companyData["youtube_channelInsight"]["data"]["daily_data"][companyData["youtube_channelInsight"]["data"]["daily_data"].length -1]["date"])} months
        </span>
        <span className="highlight">
            From: {formatDate(companyData["youtube_channelInsight"]["data"]["daily_data"][0]["date"])}
        </span>
        <span className="highlight">
            To: {formatDate(companyData["youtube_channelInsight"]["data"]["daily_data"][companyData["youtube_channelInsight"]["data"]["daily_data"].length -1]["date"])}
        </span>
    </div>
    <div className="insight-card">
        <i className="icon icon-view-duration"></i>
        <p>Average View Duration</p>
        <span className="highlight">
            {companyData["youtube_channelInsight"]["data"]["average_view_duration"].toFixed(2)} seconds
        </span>
        <progress value={companyData["youtube_channelInsight"]["data"]["average_view_duration"]} max="300"></progress>
    </div>
    
    <div className="insight-card">
        <i className="icon icon-view-percentage"></i>
        <p>Average View Percentage</p>
        <span className="highlight">
            {companyData["youtube_channelInsight"]["data"]["average_view_percentage"].toFixed(2)}%
        </span>
        <progress value={companyData["youtube_channelInsight"]["data"]["average_view_percentage"]} max="100"></progress>
    </div>
    
    <div className="insight-card">
        <i className="icon icon-subscribers-gained"></i>
        <p>Subscribers Gained</p>
        <span className="highlight">
            {companyData["youtube_channelInsight"]["data"]["total_subscribers_gained"]} subscribers
        </span>
    </div>
    
    <div className="insight-card">
        <i className="icon icon-subscribers-lost"></i>
        <p>Subscribers Lost</p>
        <span className="highlight">
            {companyData["youtube_channelInsight"]["data"]["total_subscribers_lost"]} subscribers
        </span>
    </div>
    
    <div className="insight-card">
        <i className="icon icon-total-views"></i>
        <p>Total Views</p>
        <span className="highlight">
            {companyData["youtube_channelInsight"]["data"]["total_views"]} views
        </span>
    </div>
    
    <div className="insight-card">
        <i className="icon icon-minutes-watched"></i>
        <p>Total Minutes Watched</p>
        <span className="highlight">
            {companyData["youtube_channelInsight"]["data"]["total_minutes_watched"]} minutes
        </span>
    </div>
    
    <div className="insight-card">
        <i className="icon icon-likes"></i>
        <p>Total Likes</p>
        <span className="highlight">
            {companyData["youtube_channelInsight"]["data"]["total_likes"]} likes
        </span>
    </div>
    
    <div className="insight-card">
        <i className="icon icon-dislikes"></i>
        <p>Total Dislikes</p>
        <span className="highlight">
            {companyData["youtube_channelInsight"]["data"]["total_dislikes"]} dislikes
        </span>
    </div>
    
    <div className="insight-card">
        <i className="icon icon-comments"></i>
        <p>Total Comments</p>
        <span className="highlight">
            {companyData["youtube_channelInsight"]["data"]["total_comments"]} comments
        </span>
    </div>
    
    <div className="insight-card">
        <i className="icon icon-shares"></i>
        <p>Total Shares</p>
        <span className="highlight">
            {companyData["youtube_channelInsight"]["data"]["total_shares"]} shares
        </span>
    </div>
    {/* You can also add a chart for daily data */}
    {/* <div className="chart-container">
        <h3>Daily Views & Engagement</h3>
        <canvas id="youtubeChannelChart"></canvas>
    </div> */}
</div>

                ) : (
                    <div>Loading company data...</div>
                )}
            </div> }
            {/* Buttons to switch between tables */}
            <div className="tableSwitcher">
                <button
                    className={activeTable === 'general' ? 'active' : ''}
                    onClick={() => setActiveTable('general')}
                >
                    General Data
                </button>
                <button
                    className={activeTable === 'Specific data' ? 'active' : ''}
                    onClick={() => setActiveTable('Specific data')}
                >
                    Interaction Data
                </button>
                <button
                    className={activeTable === 'viewData' ? 'active' : ''}
                    onClick={() => setActiveTable('viewData')}
                >
                    View Data
                </button>
            </div>

            {/* Render the active table based on the selected button */}
            <div className="tableContent">
                {renderTable()}
            </div>
        </div>
    );
};

export default OvertimeAnalyseTableYoutube;


export function GenerateCellStyle() {
    return (params) => {
        const currentValue = parseFloat(params.value);
        const rowIndex = params.node.rowIndex;
        // Access the previous row's value using the row index
        if (rowIndex === 0) {
            return { backgroundColor: 'white' }; // First row, no previous value
        }

        const previousRow = params.api.getDisplayedRowAtIndex(rowIndex - 1);
        const previousValue = previousRow ? parseFloat(previousRow.data.instagram_insta_impressions) : null;
        params.value = `${previousValue - currentValue} | ${currentValue}`;

        // Apply conditional coloring based on comparison
        if (previousValue === null) {
            return {
                fontWeight: 'bold'}; // No previous value, skip comparison
        }

        return {
            color: currentValue >= previousValue ? 'rgb(0,185,0)' : 'rgb(185,0,0)',
            fontWeight: 'bold',
        };
    };
}
