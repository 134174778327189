import React, {useState} from "react";
import "./Connection.css";
import PasswordRecoveryModal from "../PasswordRecoverModal/PasswordRecoverModal";
import CreateAccountModal from "../AccountCreation/AccountCreation";
import Cookies from "js-cookie";
import { isCookieCredentialStillValid } from "../../DatabaseCommunication/GetDataFromUser";

const UserConnecting = () => 
{

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenCreatingAcc, setIsModalOpenCreatingAcc] = useState(false);

    const openModal = () => 
    {
        setIsModalOpen(true);
    };

    const closeModal = () => 
    {
        setIsModalOpen(false);
    };

    const ConnectUser = async () =>
    {
        const username = document.getElementById("username").value;
        const password = document.getElementById("password").value;

        if (username && password)
        {
            Cookies.set('authPulseUsername', username, {expires: 7});
            Cookies.set('authPulsePassword', password, {expires: 7});
            if (await isCookieCredentialStillValid())
            {
                window.location.href = '/Account';
            }
            else
            {
                document.getElementById("errorConn").textContent = "credentials error";
            }
        }
    }

    return (
        <section className="connectionCard">
            <div className="headerText">Connect with your Pulse account</div>
            <div className="connectionLabel">
                <label htmlFor="username">Username</label>
                <input type="text" id="username" className="inputField" placeholder="Enter your username" />
            </div>
            <div className="connectionLabel">
                <label htmlFor="password">Password</label>
                <input type="password" id="password" className="inputField" placeholder="Enter your password" />
            </div>
            <div className="connectionLabel">
                    <button className="directLink" onClick={openModal}>Recover Password</button>
                    <PasswordRecoveryModal isOpen={isModalOpen} closeModal={closeModal} />
                    <button className="directLink" onClick={() => setIsModalOpenCreatingAcc(true)}>Create Account</button>
                    <CreateAccountModal isOpen={isModalOpenCreatingAcc} closeModal={() => setIsModalOpenCreatingAcc(false)} />
                    <div id="errorConn" style={{color: 'red'}}></div>
            </div>
            <button className="connectButton" onClick={ConnectUser}>Connect</button>
        </section>
    );
}

export default UserConnecting;
