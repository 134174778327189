import React, { useState, useEffect } from 'react';
import './SideModal.css';

const SideModal = ({ content, isOpen, onClose }) => 
{
    const [visible, setVisible] = useState(false);

    useEffect(() => 
    {
        if (isOpen) 
        {
            setVisible(true);
        }
        else 
        {
            setTimeout(() => setVisible(false), 1000); // Matches the animation duration
        }
    }, [isOpen]);

    if (!visible) 
    {
        return null;
    }

    return (
        <div className={`side-modal ${isOpen ? 'slide-in' : 'slide-out'}`}>
            <button className="close-btn" onClick={onClose}>X</button>
            <div className="content">
                {content}
            </div>
        </div>
    );
};

export default SideModal;
