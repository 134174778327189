import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetActualUserId } from '../../DatabaseCommunication/GetDataFromUser';
import { hostLink } from '../../DatabaseCommunication/GetDataFromUser';
import './connectionPage.css';
import './../../App.css';

const WelcomePage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate(); // Hook for navigation

    const SaveDatas = () => {
        // Your implementation of SaveDatas
    };

    const TryConnectingWithId = () => {
        SaveDatas();
        setErrorMessage(''); // Clear previous error messages

        fetch(`${hostLink}/IsUserCredValid`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, password })
        })
        .then(response => response.json())
        .then(data => {
            if (data.isValid) {
                // Navigate to the company feed
                navigate('/echowave/companyFeed'); // Use useNavigate to go to the new route
            } else {
                setErrorMessage(`Error: ${data.message}`);
            }
        })
        .catch(error => {
            setErrorMessage(`Error: ${error.message}`);
        });
    };

    const LaunchingPage = async () => {
        const cardData = document.getElementById('cardData');

        const userId = await GetActualUserId();
        console.log(userId);

        if (userId.hasaccess)
        {
            cardData.innerHTML = `<div id="${userId.hasaccess ? 'success' : 'error'}">${userId.message}</div>`;  

            setTimeout(() => {
                navigate('/echowave/profil');
            }, 1500);
            return;

        }
        

        
        const hasaccess = await fetch(hostLink + "/GetUserIsSubEchowave", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({customer_id : userId}),
        });
        const finalResult = await hasaccess.json();
            
        cardData.innerHTML = `<div id="${finalResult.hasaccess ? 'success' : 'error'}">${finalResult.message}</div>`;  
        if (finalResult.hasaccess == false)
        {
            setTimeout(() => 
            {
                navigate("/Account");                
            }, 1500);
        }
        else
        {
            cardData.innerHTML = `<div id="success">Redirecting you to EchoWave...</div>`;  

        }
        
        fetch(`${hostLink}/GetUserIsSubEchowave`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ customer_id: userId })
        })
        .then(response => response.json())
        .then(data => {
            if (data.hasaccess) { 
                setTimeout(() => {
                    navigate('/echowave/profil');
                }, 1500);
            } else {           
            }
        })
        .catch(error => {
        });
    };

    useEffect(() => {
        LaunchingPage(); // Trigger on component mount
    }, []);

    return (
      <div id="connectPage">
        <div className="connection">
            <div className="card" id="cardData">
                <div>Checking server information...</div>
            </div>
        </div>
      </div>
    );
};

export default WelcomePage;
