import React, { useState, useEffect } from "react";
import "../../App.css";
import "./Roadmap.css";
import { hostLink } from "../../DatabaseCommunication/GetDataFromUser";

const RoadMap = () => {
    const [roadmapData, setRoadmapData] = useState({
        planning: [],
        doing: [],
        done: []
    });
    const [visibleColumns, setVisibleColumns] = useState({
        planning: true,
        doing: true,
        done: true
    });

    useEffect(() => {
        // Fetch roadmap data from backend
        const fetchRoadmapData = async () => {
            try {
                const response = await fetch(hostLink + "/GetRoadmap"); // Replace with your actual backend URL
                if (!response.ok) throw new Error("Failed to fetch roadmap data");
                const data = await response.json();

                let finalData = {
                    planning: [],
                    doing: [],
                    done: []
                }


                data.roadmapContent.forEach(element => {
                    finalData[element[3]].push(element);
                });
                console.log(finalData);
                setRoadmapData(finalData);
            } catch (error) {
                console.error("Error fetching roadmap data:", error);
            }
        };

        fetchRoadmapData();
    }, []);

    const toggleColumn = (column) => {
        setVisibleColumns((prevState) => ({
            ...prevState,
            [column]: !prevState[column]
        }));
    };

    const calculateColor = (progress) => {
        const red = Math.round(255 * (1 - progress / 100));
        const green = Math.round(255 * (progress / 100));
        return `rgb(${red}, ${green}, 0)`;
    };
    const renderCards = (items) => {
        // Ensure 'items' is an array before attempting to map over it
        if (!Array.isArray(items)) {
            console.error("Expected 'items' to be an array, but received:", items);
            return null; // or an empty array, as needed
        }
        
        return items.map(item => {
            const progress = calculateProgress(item[5], item[6]);
            return (
                <div key={item[0]} className={`roadmap-card ${item[3]}`}>
                    <h4>{item[1]}</h4>
                    <p>{item[2]}</p>
                    <p><strong>State:</strong> {item[4]}</p>
                    <p><strong>Debut Date:</strong> {item[5]}</p>
                    <p><strong>End Date:</strong> {item[6]}</p>
                    <div className="progress-bar">
                        <div className="progress" style={{ width: `${progress}%`, backgroundColor: calculateColor(progress) }}></div>
                    </div>
                </div>
            );
        });
    };
    
    

    const calculateProgress = (debutDate, endDate) => {
        const start = new Date(debutDate).getTime();
        const end = new Date(endDate).getTime();
        const today = new Date().getTime();
        const progress = ((today - start) / (end - start)) * 100;
        return Math.min(Math.max(progress, 0), 100);
    };

    return (
        <section className="containerMainRdMp">
            <div className="containerRoadMap">
                <h3>Roadmap Echowave End 2024</h3>
                <div className="roadmap-columns">
                    {Object.keys(roadmapData).map((column) => (
                        <div className="column" key={column}>
                            <h4 className="column-title" onClick={() => toggleColumn(column)}>
                                {column.charAt(0).toUpperCase() + column.slice(1)} {visibleColumns[column] ? '-' : '+'}
                            </h4>
                            {visibleColumns[column] && renderCards(roadmapData[column])}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default RoadMap;
