import React from "react";
import { useState, useEffect} from "react";
import '../../CircularGraphHours/CircularGraphHours.css';
import { GetUserCompanyData } from "../../../../GatherUserInformationDB/GatherDatabaseUser";
import { GenerateCellStyle } from "../OvertimeAnalyseTable";
import CreateTable from "../TableClass";
import ChartModule from "../../../chartMaker/ChartModule";

const YoutubeGeneralDataTable = () =>
{        const [rowTableGeneral, setRowTableGenerale] = useState([]);
    const [colTableGeneral, setColTableGeneral] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [graphLabel, setGraphLabel] = useState(["subscribers", "videosCount", "viewsCount"]);


    const GetDatas = async() =>
    {    
        let allRow = [];
        let finalDataCol = [];
        finalDataCol.push({field: 'id'})
        let graphDataFormatted = [];
        const compData = await GetUserCompanyData();
        finalDataCol.push({field: "youtube_subscriber", cellStyle: GenerateCellStyle()})
        finalDataCol.push({field: "youtube_videoCount", cellStyle: GenerateCellStyle()})
        finalDataCol.push({field: "youtube_viewCount", cellStyle: GenerateCellStyle()})

        compData.forEach((list, index) =>
        {
            let finalDataRow = {};
            finalDataRow['id'] = index;
            finalDataRow['youtube_subscriber'] = list['youtube_subscriber'];
            finalDataRow['youtube_videoCount'] = list['youtube_videoCount'];
            finalDataRow['youtube_viewCount'] = list['youtube_viewCount'];
        
            allRow.push(finalDataRow);
            graphDataFormatted.push({
                id: index,
                subscribers: list['youtube_subscriber']/1,
                videosCount: list['youtube_videoCount']/1,
                viewsCount: list['youtube_viewCount']/1,
            });
        });

        setColTableGeneral(finalDataCol);
        setRowTableGenerale(allRow);
        console.log(graphDataFormatted);
        setGraphData(graphDataFormatted);
    }

    useEffect(() => 
    {
        GetDatas();
    }, []);
    return (
        <div>
            <h1>Youtube general data</h1>
            <CreateTable col= {colTableGeneral} row={rowTableGeneral}/>
            <ChartModule
                chartTitle="YouTube General Data Overview"
                dataSets={[graphData]}    // Use the fetched graph data
                chartType="line"
                xField="id"
                yFields={graphLabel}      // Label fields for Y-axis
                theme="ag-pastel"
                height={500}
            />
    </div>
    );
}

export default YoutubeGeneralDataTable;