import React from "react";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

const CreateTable = ({ col, row }) => 
{
    const rowDataCopy = [...row];
    
    const { sumRow, averageRow } = GenerateTotalAndAverageRow(rowDataCopy);
    rowDataCopy.push(sumRow);
    rowDataCopy.push(averageRow);


    return (
        <div className="ag-theme-quartz" style={{ height: 500 }}>
            <AgGridReact 
                rowData={rowDataCopy} 
                columnDefs={col} 
            />
        </div>
    );

    function GenerateTotalAndAverageRow(row) {
        let sumRow = {};
        let averageRow = {};
    
        // Calculate the sum for each column
        row.forEach(data => {
            for (const key in data) {
                if (key === "id") {
                    sumRow[key] = "sum";
                    averageRow[key] = "average";
                } else {
                    if (typeof data[key] === 'number') {
                        // Sum up the values for each column
                        sumRow[key] = (sumRow[key] || 0) + data[key];
                    }
                }
            }
        });
    
        // Calculate the average based on the sumRow
        for (const key in sumRow) {
            if (typeof sumRow[key] === 'number') {
                averageRow[key] = sumRow[key] / row.length;  // Compute the average
            }
        }
    
        return { sumRow, averageRow };
    }
};

export default CreateTable;