import React from "react";
import { useState, useEffect} from "react";
import '../CircularGraphHours/CircularGraphHours.css';
import { GetUserCompanyData } from "../../../GatherUserInformationDB/GatherDatabaseUser";
import { GenerateCellStyle } from "./OvertimeAnalyseTable";
import CreateTable from "./TableClass";

const CountryInformationTable = () =>
{        const [rowTableGeneral, setRowTableGenerale] = useState([]);
    const [colTableGeneral, setColTableGeneral] = useState([]);

    const GetDatas = async() =>
    {    
        let allRow = [];
        let finalDataCol = [];
        finalDataCol.push({field: 'id'})
        const compData = await GetUserCompanyData();
        console.log(compData[0]['instagram_top_countries'])
        compData[0]['instagram_top_countries'].forEach(element => {
            finalDataCol.push({
                field: element["label"],
                cellStyle: GenerateCellStyle()
            });      
            
        });


        compData.forEach((list, index) =>
        {
            let finalDataRow = {};
            finalDataRow["id"] = index;
            list["instagram_top_countries"].forEach(element => {                
                finalDataRow[element["label"]] = element["value"];
            });
    
            allRow.push(finalDataRow);
        });

        setColTableGeneral(finalDataCol);
        setRowTableGenerale(allRow);
    }

    useEffect(() => 
    {
        GetDatas();
    }, []);
    return (
        <div>
            <h1>Amount of follower per country</h1>
            <CreateTable col= {colTableGeneral} row={rowTableGeneral}/>
    </div>
    );
}

export default CountryInformationTable;