import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import Footer from './components/Footer';
import RoadMap from './components/Roadmap';
import LoadingModule from '../loading/loading';
import { hostLink } from '../DatabaseCommunication/GetDataFromUser';
import SideModal from '../popup/popup';
import EchowavePopup from './EchowavePopupOffer/EchowavePopup';

function App() {
    const [isLoading, setLoadingState] = useState(true);

    useEffect(() => {
        const fetchData = async () => 
        {
            const result = await fetch(hostLink + '/ping', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log(result);

            if(result.status == 200)
            {
                setLoadingState(false);
            }

        };

        fetchData();
    }, []);

    const [isModalOpen, setModalOpen] = useState(true);
    // Set page title dynamically using useEffect
    useEffect(() => {
        document.title = "Pulse Software | Introduction to EchoWave"; // Adjust as needed
    }, []);

    return (
        <div className="App">
            {isLoading ? (
                <LoadingModule />
            ) : (
                <>
                    <SideModal
                        content={<EchowavePopup />} // Pass any component here
                        isOpen={isModalOpen}
                        onClose={() => setModalOpen(false)}
                    />

                    <Header />
                    <Hero />
                    <Features />
                    <RoadMap />
                    <Footer />
                </>
            )}
        </div>
    );
}

export default App;
