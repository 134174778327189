import React, { useState } from 'react';
import "./PasswordRecoverModal.css"

const PasswordRecoveryModal = ({ isOpen, closeModal }) => 
{
    const [email, setEmail] = useState('');

    const handleEmailChange = (event) => 
    {
        setEmail(event.target.value);
    };

    const handleSendRecoveryEmail = () => 
    {
        // Handle sending the recovery email
        console.log('Recovery email sent to:', email);
        // Close the modal after sending the email
        closeModal();
    };

    if (!isOpen) 
    {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Password Recovery</h2>
                <p>Please enter your email to receive the recovery instructions.</p>
                <input
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="Enter your email"
                />
                <button onClick={handleSendRecoveryEmail}>Send Recovery Email</button>
                <button onClick={closeModal}>Close</button>
            </div>
        </div>
    );
};

export default PasswordRecoveryModal;
