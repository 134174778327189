import React, { useEffect } from 'react';
import EchoWaveLandingApp from './EchowaveLandingPage/EchoWaveLandingPage';
import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import AccountPage from './AccountManager/AccountLandingPage';
import GetStartedEchoWave from './EchowaveLandingPage/GetStartedEchoWave/GetStartedEchoWave';
import PrivacyPolicyPage from './contract/PrivacyPolicy/PrivacyPolicy';
import TermOfService from './contract/term of service/term of service';
import CompanyFeed from './EchowaveAppContent/CompanyFeed';
import WelcomePage from './EchowaveAppContent/Connexion/ConnectionPage';
import AccountPageManager from './EchowaveAppContent/ProfilManager/profil';
import NetworkPage from './EchowaveAppContent/Network/Network';

function App() {

    // Set page title dynamically using useEffect
    useEffect(() => {
        document.title = "Pulse software"; // Change this title as needed
    }, []); // Empty dependency array means this runs once when the component mounts

    return (
        <div className="App">
            <BrowserRouter>
                <div>
                   <Routes>
                        <Route path='/' element={<EchoWaveLandingApp/>}/>
                        <Route path='/Account' element={<AccountPage/>}/>
                        <Route path='/getaccessechowave' element={<GetStartedEchoWave/>}/>
                        <Route path='/privacypolicy' element={<PrivacyPolicyPage/>}/>
                        <Route path='/termsrv' element={<TermOfService/>}/>

                        
                        <Route path='/echowave' element={<WelcomePage/>}/>
                        <Route path='/echowave/CompanyFeed' element={<CompanyFeed/>}/>
                        <Route path='/echowave/profil' element={<AccountPageManager/>}/>
                        <Route path='/echowave/Network' element={<NetworkPage/>}/>
                    </Routes>
                </div>
            </BrowserRouter>
        </div>
    );
}

export default App;
