import React from "react";
import { useState, useEffect} from "react";
import '../../CircularGraphHours/CircularGraphHours.css';
import { GetUserCompanyData, GetYoutubeData } from "../../../../GatherUserInformationDB/GatherDatabaseUser";
import { GenerateCellStyle } from "../OvertimeAnalyseTable";
import CreateTable from "../TableClass";
import ChartModule from "../../../chartMaker/ChartModule";

const YoutubeViewDataTable = () =>
{        const [rowTableGeneral, setRowTableGenerale] = useState([]);
    const [colTableGeneral, setColTableGeneral] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [graphLabel, setGraphLabel] = useState(["views", "avrgViewDuration","avrgViewPercent","estimatedMinutesWatched"]);

    const GetDatas = async() =>
    {    
        let allRow = [];
        let finalDataCol = [];
        finalDataCol.push({field: 'id'})
        let compData = await GetYoutubeData();
        let graphDataFormatted = [];
        compData = compData.youtube_channelInsight.data.daily_data;
        finalDataCol.push({field: "date", cellStyle: GenerateCellStyle()})
        finalDataCol.push({field: "views", cellStyle: GenerateCellStyle()})
        finalDataCol.push({field: "average_view_duration", cellStyle: GenerateCellStyle()})
        finalDataCol.push({field: "average_view_percentage", cellStyle: GenerateCellStyle()})
        finalDataCol.push({field: "estimated_minutes_watched", cellStyle: GenerateCellStyle()})
        console.log(compData);
        compData.forEach((list, index) =>
        {
            let finalDataRow = {};
            finalDataRow['id'] = index;
            finalDataRow['date'] = list['date'];
            finalDataRow['views'] = list['views'];
            finalDataRow['average_view_duration'] = list['average_view_duration'];
            finalDataRow['average_view_percentage'] = list['average_view_percentage'];
            finalDataRow['estimated_minutes_watched'] = list['estimated_minutes_watched'];
        
            allRow.push(finalDataRow);
            graphDataFormatted.push({
                date: list['date'],
                views: list['views'],
                avrgViewDuration: list['average_view_duration'],
                avrgViewPercent: list['average_view_percentage'],
                estimatedMinutesWatched: list['estimated_minutes_watched'],
            });
        });

        setColTableGeneral(finalDataCol);
        setRowTableGenerale(allRow);
        setGraphData(graphDataFormatted);
    }

    useEffect(() => 
    {
        GetDatas();
    }, []);
    return (
        <div>
            <h1>Youtube general data</h1>
            <CreateTable col= {colTableGeneral} row={rowTableGeneral}/>
            <ChartModule
                chartTitle="YouTube View Data Overview"
                dataSets={[graphData]}    // Use the fetched graph data
                chartType="line"
                xField="date"
                yFields={graphLabel}      // Label fields for Y-axis
                theme="ag-pastel"
                height={500}
            />
    </div>
    );
}

export default YoutubeViewDataTable;