import React, { useState, useEffect } from 'react';
import './Hero.css';
import {Link} from "react-router-dom";

const Hero = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [fade, setFade] = useState(true);

    // Carousel texts with bold important words
    const carouselTexts = [
        <>Empowering your business with <strong>EchoWave</strong></>,
        <>Stay connected with <strong>Real-time Messaging</strong></>,
        <>Get a complete <strong>comprehension</strong> of your network's data</>,
        <>Be <strong>productive</strong> with our tools, all in one app</>
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setFade(false); // Start fading out

            setTimeout(() => {
                // Update text after fade out
                setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselTexts.length);
                setFade(true); // Start fading in
            }, 500); // Wait for the fade-out transition duration (0.5s)
        }, 3000); // Change text every 3 seconds

        return () => clearInterval(interval);
    }, [carouselTexts.length]);

    return (
        <section className="hero" id="home">
            <div className="container">
                <h1>Introducing EchoWave</h1>
                <p className={`carousel-text ${fade ? 'fade-in' : 'fade-out'}`}>
                    {carouselTexts[currentIndex]}
                </p>

                <Link to="/getaccessechowave">
                <button className="cta-button">Get Started</button>
                </Link>
            </div>
        </section>
    );
}

export default Hero;
