import React, { useEffect, useState } from 'react';
import "./AccountCreation.css"
import { CreateAccount } from '../../DatabaseCommunication/GetDataFromUser';
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";


const CreateAccountModal = ({ isOpen, closeModal }) => 
{    
    const navigate = useNavigate(); // Hook for navigation
    const [formData, setFormData] = useState({
        username: '',
        identifiant: '',
        email: '',
        password: '',
        company: '',
        accessCode : '',
        isOwner : false
    });

    const verifyFormData = () => 
        {
        for (let key in formData) 
            {
            if (formData[key] === '') 
                {
                return key;
            }
        }
        return -1;
    };

    const [isOwner, SetIsOwner] = useState(false);
    const [isAccessValid, setIsAccessValid] = useState(true); // To store access validation result


    // Handle checkbox change
    const handleCheckboxChange = () => {
        SetIsOwner(!isOwner); // Toggle the isOwner state
    };

    const handleInputChange = (e) => 
    {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCreateAccount = async() => 
    {
        // Handle create account logic
        console.log('Account created with data:', formData);
        const outputDiv = document.getElementById('outputForm');
        outputDiv.textContent = "";

        formData.isOwner = isOwner;     
        const key = verifyFormData();   
        if(key != -1)
        {
            if(key === "accessCode")
            {
                if(!isOwner)
                {
                    outputDiv.textContent=`${key} cannot be empty`;
                    return;
                }
            }
            else
            {
                outputDiv.textContent=`${key} cannot be empty`;
                return;

            }
        }

        const reponse = await CreateAccount(formData);

        outputDiv.textContent = reponse.data.message;
        if (reponse.status == 201) 
        {
            closeModal();
            setTimeout(() => {
                Cookies.set('authPulseUsername', formData.identifiant, {expires: 7});
                Cookies.set('authPulsePassword', formData.password, {expires: 7});
                navigate("/Echowave");                
            }, 1500);

        }
    };

    if (!isOpen) 
    {
        return null;
    }

    return (
        <div className="modalOverlay">
            <div className="createAccountModal">
                <h2 className="createAccountHeader">Create Account</h2>

                <div className="accountField">
                    <label>Username</label>
                    <input
                        type="text"
                        name="username"
                        value={formData.username}
                        onChange={handleInputChange}
                        className="accountInputField"
                        placeholder="Enter your username"
                    />
                </div>

                <div className="accountField">
                    <label>Identifiant</label>
                    <input
                        type="text"
                        name="identifiant"
                        value={formData.identifiant}
                        onChange={handleInputChange}
                        className="accountInputField"
                        placeholder="Enter your identifiant"
                    />
                </div>

                <div className="accountField">
                    <label>Email</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        className="accountInputField"
                        placeholder="Enter your email"
                    />
                </div>

                <div className="accountField">
                    <label>Password</label>
                    <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        className="accountInputField"
                        placeholder="Enter your password"
                    />
                </div>

                <label>Are you creating a company in our system ?</label>
                
                    <input
                        type="checkbox"
                        onChange={handleCheckboxChange}
                        checked={isOwner}  // Bind checked state to the input
                    />

                <div className="accountField">
                    {isOwner && (
                        <>
                            <label>Company</label>
                            <input
                                type="text"
                                name="company"
                                value={formData.company}
                                onChange={handleInputChange}
                                className="accountInputField"
                                placeholder="Enter your company name"
                            />
                        </>
                    )}
                    {!isOwner && (
                        <>
                        <label>Company Name</label>
                        <input
                                type="text"
                                name="company"
                                value={formData.company}
                                onChange={handleInputChange}
                                className="accountInputField"
                                placeholder="Enter the company name"
                            />
                        <label>Company Access code</label>
                        <input
                                type="text"
                                name="accessCode"
                                value={formData.accessCode}
                                onChange={handleInputChange}
                                className="accountInputField"
                                placeholder="Enter the company access code"
                            />
                        </>
                    )

                    }
                </div>
                <div id='outputForm'></div>

                <button onClick={handleCreateAccount} className="createAccountButton">Create Account</button>
                <button onClick={closeModal} className="closeModalButton">Close</button>
            </div>
        </div>
    );
};

export default CreateAccountModal;
