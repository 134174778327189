import React, { useState } from "react";
import '../CircularGraphHours/CircularGraphHours.css';
import GeneraleDataTable from "./GeneralInformationTable";
import MenInformationTable from "./MenInformationTable";
import WomenInformationTable from "./WomenInformationTable";
import CityInformationTable from "./CityTable";
import CountryInformationTable from "./CountryTable";
import ProductDataTable from "./ProductInformationTable";
import LoadingSpinner from "../../../loading/LoadingSpinner";

const OvertimeAnalyseTable = () => 
{
    const [activeTable, setActiveTable] = useState('general'); // State to track the active table

    const renderTable = () => {
        switch (activeTable) {
            case 'general':
                return <GeneraleDataTable />;
            case 'men':
                return <MenInformationTable />;
                case 'women':
                    return <WomenInformationTable />;
                case 'city':
                    return <CityInformationTable />;
                case 'country':
                    return <CountryInformationTable />;               
                case 'product':
                    return <ProductDataTable />;
            default:
                return <GeneraleDataTable />;
        }
    };

    return (
        <div className="containerNetwork">
            <h1>What is your evolution over time on Instagram?</h1>
            <hr />
            <h3>Here is all your data in a table over time.</h3>

            {/* Buttons to switch between tables */}
            <div className="tableSwitcher">
                <button
                    className={activeTable === 'general' ? 'active' : ''}
                    onClick={() => setActiveTable('general')}
                >
                    General Data
                </button>
                <button
                    className={activeTable === 'men' ? 'active' : ''}
                    onClick={() => setActiveTable('men')}
                >
                    Men's Data
                </button>
                <button
                    className={activeTable === 'women' ? 'active' : ''}
                    onClick={() => setActiveTable('women')}
                >
                    Women's Data
                </button>
                <button
                    className={activeTable === 'city' ? 'active' : ''}
                    onClick={() => setActiveTable('city')}
                >
                    City's Data
                </button>
                <button
                    className={activeTable === 'country' ? 'active' : ''}
                    onClick={() => setActiveTable('country')}
                >
                    Country's Data
                </button>
                <button
                    className={activeTable === 'product' ? 'active' : ''}
                    onClick={() => setActiveTable('product')}
                >
                    Product's Data
                </button>
            </div>

            {/* Render the active table based on the selected button */}
            <div className="tableContent">
                {renderTable()}
            </div>
        </div>
    );
};

export default OvertimeAnalyseTable;



export function GenerateCellStyle() 
{
    return (params) => 
    {
        const currentValue = parseFloat(params.value);
        const rowIndex = params.node.rowIndex;

        // If it's the first row, no previous value to compare
        if (rowIndex === 0 || isNaN(currentValue)) 
        {
            return { backgroundColor: 'white' };
        }

        // Get previous row's value
        const previousRow = params.api.getDisplayedRowAtIndex(rowIndex - 1);
        const previousValue = previousRow ? parseFloat(previousRow.data[params.colDef.field]) : null;

        // Ensure valid previous value
        if (previousValue === null || isNaN(previousValue)) 
        {
            return { fontWeight: 'normal' }; // No previous value to compare
        }

        // Apply conditional coloring based on comparison
        return {
            color: currentValue > previousValue ? 'green' : (currentValue < previousValue ? 'red' : 'black'),
            fontWeight: currentValue > previousValue ? 'bold' : (currentValue < previousValue ? 'bold' : 'normal'),
        };
    };
}




