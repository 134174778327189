import React, { useState, useEffect } from 'react';
import './Features.css';
import FeatureItem from './FeatureItem';
import { Link } from 'react-router-dom';

const Features = () => {
    // State to hold the current hovered item's data
    const featureItems = [
        {
            title: 'Real-time Messaging',
            descriptionSmall: 'Instant and secure messaging for teams and businesses.',
            descriptionBig: 'Stay connected with seamless messaging and collaboration tools.',
            imageUrl: 'https://images.unsplash.com/photo-1662974770404-468fd9660389?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        },
        {
            title: 'Network Analysis',
            descriptionSmall: 'Complete analysis from Instagram/TikTok/YouTube and more.',
            descriptionBig: 'Gain insights on audience engagement and growth trends across multiple platforms.',
            imageUrl: 'https://images.unsplash.com/photo-1526628953301-3e589a6a8b74?q=80&w=2006&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        },
        {
            title: 'Manage Company',
            descriptionSmall: 'Manage your company with all our tools.',
            descriptionBig: 'Track projects, manage teams, and boost productivity with advanced tools.',
            imageUrl: 'https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?q=80&w=2047&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        }
    ];

    const [indexToShow, setIndexToShow] = useState(0);
    const [fade, setFade] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setFade(false);
            setTimeout(() => {
                setIndexToShow((prevIndex) => (prevIndex + 1) % featureItems.length);
                setFade(true); // Start fading in
            }, 500); // Wait for the fade-out transition duration (0.5s)
        }, 6000); // Change image every 6 seconds

        return () => clearInterval(interval);
    }, [featureItems.length]);

    return (
        <section className="features" id="features">
            <div className="container">
                <h2 className="features-title">Why Choose EchoWave?</h2>
                <div className="feature-list">
                    {featureItems.map((item, index) => (
                        <FeatureItem 
                            key={index}
                            title={item.title} 
                            descriptionSmall={item.descriptionSmall} 
                            descriptionBig={item.descriptionBig}
                            imageUrl={item.imageUrl} // Clear when mouse leaves
                        />
                    ))}
                </div>

                <div className={`hover-box ${fade ? 'fade-in' : 'fade-out'}`}>
                    <div className="feature-container">
                        <div className="feature-text">
                            <h3>{featureItems[indexToShow].title}</h3>
                            <p>{featureItems[indexToShow].descriptionBig}</p>
                        </div>
                        {featureItems[indexToShow].imageUrl && 
                            <div className="feature-image">
                                <img src={featureItems[indexToShow].imageUrl} alt={`${featureItems[indexToShow].title} icon`} />
                            </div>
                        }
                    </div>
                </div>

                <div className='offers-section'>
                    <h3 className='offers-title'>What EchoWave Can Offer You</h3>
                    <ul className='what-offers-list'>
                        <li><strong>Streamlined Data Collection:</strong> Automate the gathering of social media data to save time and reduce manual errors.</li>
                        <li><strong>Comprehensive Reporting:</strong> Create detailed reports and visualizations that help in understanding your data better.</li>
                        <li><strong>Performance Insights:</strong> Get actionable insights to enhance your social media strategy and improve engagement.</li>
                        <li><strong>Long-term Tracking:</strong> Monitor trends over time to adapt your strategies effectively and stay ahead of the competition.</li>
                        <li><strong>Enhanced Decision Making:</strong> Leverage data-driven insights to make informed business decisions and improve your marketing efforts.</li>
                    </ul>

                    <h3 className='offers-title'>Empower Your HR Management</h3>
                    <ul className='what-offers-list'>
                        {/* <li><strong>Efficient Recruitment Process:</strong> Streamline your hiring with automated candidate tracking and easy collaboration tools, ensuring you find the right talent faster.</li> */}
                        <li><strong>Centralized Employee Database:</strong> Maintain a comprehensive and accessible employee record system that enhances communication and information sharing.</li>
                        <li><strong>Real-Time Performance Tracking:</strong> Monitor employee performance with intuitive dashboards, enabling timely feedback and recognition to boost morale.</li>
                        <li><strong>Employee Engagement Insights:</strong> Leverage analytics to understand employee satisfaction and engagement, helping you create a positive workplace culture.</li>
                        <li><strong>Employee Time Management:</strong> Efficiently manage work hours. Employees can submit requests for time off, report health issues, or communicate any other work-related concerns through the app.</li>
                        {/* <li><strong>Compliance and Reporting:</strong> Stay compliant with labor laws and regulations effortlessly with built-in reporting tools that simplify audits and track changes.</li> */}
                        {/* <li><strong>Training and Development Tools:</strong> Facilitate employee growth through personalized training plans and progress tracking, ensuring your team stays skilled and competitive.</li> */}
                    </ul>

                    <h3 className='offers-title'>Elevate Your Project Management</h3>
                    <ul className='what-offers-list'>
                        <li><strong>Integrated Task Management:</strong> Organize tasks seamlessly with customizable boards that allow for easy assignment, tracking, and collaboration among team members.</li>
                        <li><strong>Real-Time Collaboration:</strong> Foster teamwork with instant communication tools and file sharing, ensuring everyone is aligned and working towards common goals.</li>
                        <li><strong>Timelines:</strong> Visualize project timelines to effectively plan, track progress, and meet deadlines, while managing resources efficiently.</li>
                        <li><strong>Resource Allocation Tools:</strong> Optimize resource distribution across projects to maximize productivity and minimize costs, ensuring every project is well-staffed.</li>
                        <li><strong>Detailed Project Reporting:</strong> Generate insightful reports that analyze project performance, helping you identify areas for improvement and drive success.</li>
                        <li><strong>Risk Management Features:</strong> Proactively identify potential project risks and develop mitigation strategies, ensuring your projects stay on track.</li>
                    </ul>
                </div>

                <div className='beta-section'>
                    <h3 className='beta-title'>Echowave is Growing with You</h3>
                    <p className='beta-description'>
                        Wanna be a part of the process? <br />
                        Join our <strong style={{ color: 'red' }}>beta tester</strong> community!
                    </p>
                    <hr />
                    <h3 className='beta-program-title'>What is the Beta Tester Program?</h3>
                    <p className='beta-description'>
                        Interested in <strong>helping</strong> us make this app a complete helper for companies? <br />
                        With our beta tester program, you will get <strong>full access to the app</strong> by paying 
                        <strong className="launching-price"> the launching price</strong>. <br />
                        With this access, you will have the opportunity to:
                    </p>
                    <ul className='what-offers-list'>
                        <li><strong>Full app access:</strong> Nothing more to pay, have access to everything in the app.</li>
                        <li><strong>Provide Feedback:</strong> Share your thoughts and suggestions.</li>
                        <li><strong>Direct Communication:</strong> Talk with our team directly.</li>
                        <li><strong>Influence Development:</strong> Help shape the features you want to see.</li>
                        <li><strong>Future App Contributor:</strong> You’ll have the opportunity to suggest app ideas when our newsletter is released.</li>
                        <li><strong>Real-time Messaging:</strong> Enjoy instant and secure messaging features for seamless collaboration.</li>
                    </ul>
                    <Link to="/getaccessechowave">
                        <button className="cta-button">Join us</button>
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default Features;
