import React, { useEffect } from 'react';
import { AgCharts } from 'ag-charts-community';

const CircularGraph = ({ datas }) => {
  useEffect(() => {
    const chartContainer = document.getElementById('myChart');
    // Clear previous charts if any
    chartContainer.innerHTML = '';

    const data = datas;
    const maxAccounts = Math.max(...data.map(item => item.value));
    const minAccounts = Math.min(...data.map(item => item.value));
    const totalAccounts = data.reduce((acc, item) => acc + item.value, 0);
    const averageAccounts = totalAccounts / data.length;

    // Function to clamp the hour between 0 and 23 and handle invalid input
    const normalizeHour = (hour) => {
      if (typeof hour === 'number' && hour >= 0 && hour <= 23) {
        return hour;
      }
      return 12; // Default to midday (12:00) if hour is invalid
    };

    const mapHourToBellCurve = (hour) => {
      const mean = 12; // Peak value at noon
      const stdDev = 5; // Standard deviation to control the width of the bell curve
    
      // Calculate the bell curve value
      const exponent = -((hour - mean) ** 2) / (2 * stdDev ** 2);
      const bellValue = Math.exp(exponent);
    
      return bellValue; 
    };

    const getColorForHour = (hour) => {
      const normalizedHour = normalizeHour(hour);

      let hourNormalized = normalizedHour / 23; // Normalize hour between 0 and 1 (23 max to include all hours)
      hourNormalized = mapHourToBellCurve(normalizedHour);
      const nightColor = { r: 0, g: 0, b: 139 }; // Dark blue (RGB: 0, 0, 139)
      const dayColor = { r: 205, g: 205, b: 0 }; // Yellow (RGB: 255, 255, 0)

      const r = Math.round(nightColor.r + (dayColor.r - nightColor.r) * hourNormalized);
      const g = Math.round(nightColor.g + (dayColor.g - nightColor.g) * hourNormalized);
      const b = Math.round(nightColor.b + (dayColor.b - nightColor.b) * hourNormalized);

      return `rgb(${r}, ${g}, ${b})`; // Return the interpolated RGB color
    };

    // Calculate radius based on accounts normalized to 0-100
    const processedData = data.map(item => ({
      hour: item.label,
      accounts: item.value,
      size: averageAccounts / 2 + ((item.value - minAccounts) / (maxAccounts - minAccounts)) * averageAccounts * 2,
      sizeAngl: 1,
      color: getColorForHour(item.label),
    }));

    const options = {
      container: chartContainer,
      title: {
        text: 'Number of Accounts Touched per Hour',
        fontSize: 18,
        fontWeight: 'bold',
      },
      series: [
        {
          data: processedData,
          type: 'pie',
          angleKey: 'sizeAngl',
          radiusKey: 'size',
          calloutLabelKey: 'hour',
          sectorLabelKey: 'accounts',
          cornerRadius: 15,
          fills: processedData.map(item => item.color), // Use generated colors
          tooltip: {
            renderer: ({ datum }) => {
              return {
                title: `Accounts: ${datum["accounts"].toLocaleString()}`,
                content: `Hour: ${datum["hour"]}`,
              };
            },
          },
          label: {
            visible: true,
            formatter: (params) => `${params.datum.hour}: ${params.datum.accounts}`,
          },
        },
      ],
      legend: {
        enabled: false
      },
      padding: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20,
      },
    };

    AgCharts.create(options);
  }, [datas]);

  return (
    <div id="myChart" style={{ width: '1000px', height: '800px' }} />
  );
};

export default CircularGraph;
