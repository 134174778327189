import React, { useState, useEffect } from 'react';
import './CircularGraphHours.css';
import CircularGraph from './GraphGenerator';
import {  GetUserCompanyData } from '../../../GatherUserInformationDB/GatherDatabaseUser';
import LoadingSpinner from '../../../loading/LoadingSpinner.js';


function CircularGraphHours() {
    const [dataOptionsValue, setDataOptionsValue] = useState({
        circularInfluenceColor: false,
        circularAverage: false,
        circularGrid: false
    });

    const [isLoading, SetIsLoading] = useState(true);
    const [day, setDay] = useState(0);
    const handleDayChange = (event) => {
        setDay(parseInt(event.target.value)); // Convert the value to a number
    };
    
    // State to hold the graph data
    const [data, setData] = useState([
    ]);

    const ChangeHoursData = () => {
        console.log('Hours data changed:', dataOptionsValue);
        // Add the logic for changing the hours data based on the state
    };

    const toggleOption = (option) => {
        setDataOptionsValue(prevState => ({
            ...prevState,
            [option]: !prevState[option]
        }));
        ChangeHoursData();
    };

    const GetDatas = async() =>
    {

        const compData = await GetUserCompanyData();
        const daysDataInfluence = compData[compData.length-1].instagram_per_hour_follow;
        const data = daysDataInfluence[day].data_points;
        const finalData = [];
        data.forEach((hour, index) => 
            {
            finalData.push({'label' : index, 'value' : hour['value']}); // Set the current index value
        });
        setData(finalData);
        SetIsLoading(false);
    }

    useEffect(() =>
    {
        GetDatas();    
    }, [day])

    if (isLoading)
    {
        return(
            <LoadingSpinner/>
        )
    }

    return (
        <div>
            <h1 id="statsStatisticsName">Statistics</h1>
            <div className="containerNetwork">
                <div className="stats-label">Which day to publish?</div>
                <hr className="stats-separator" />
                <div className="stats-label">Based on the last month:</div>
                <div className="stats-content" id="stats-last-month"></div>
                <div className="stats-chart-and-options-container">
                    <div className="stats-chart-options-card" id="stats-chart-options-card">
                        {/* <div className="stats-one-option-block">
                            <div className="stats-checkbox-container">
                                <input 
                                    type="checkbox" 
                                    id="stats-colorInfluenceGraph" 
                                    name="stats-colorInfluenceGraph" 
                                    checked={dataOptionsValue.circularInfluenceColor} 
                                    onChange={() => toggleOption('circularInfluenceColor')} 
                                />
                                <label htmlFor="stats-colorInfluenceGraph">Color influence</label>
                            </div>
                        </div>
                        <div className="stats-one-option-block">
                            <div className="stats-checkbox-container">
                                <input 
                                    type="checkbox" 
                                    id="stats-AverageLineGraphButton" 
                                    name="stats-AverageLineGraphButton" 
                                    checked={dataOptionsValue.circularAverage} 
                                    onChange={() => toggleOption('circularAverage')} 
                                />
                                <label htmlFor="stats-AverageLineGraphButton">Average line</label>
                            </div>
                        </div>
                        <div className="stats-one-option-block">
                            <div className="stats-checkbox-container">
                                <input 
                                    type="checkbox" 
                                    id="stats-gridLineGraphButt" 
                                    name="stats-gridLineGraphButt" 
                                    checked={dataOptionsValue.circularGrid} 
                                    onChange={() => toggleOption('circularGrid')} 
                                />
                                <label htmlFor="stats-gridLineGraphButt">Grid line</label>
                            </div>
                        </div> */}
                        <div className="stats-one-option-block">
                            <div className="stats-checkbox-container">
                                <div>Change day</div>
                            </div>
                            <select value={day} onChange={handleDayChange} className="day-selector">
                                <option value={0}>Monday</option>
                                <option value={1}>Tuesday</option>
                                <option value={2}>Wednesday</option>
                                <option value={3}>Thursday</option>
                                <option value={4}>Friday</option>
                                <option value={5}>Saturday</option>
                                <option value={6}>Sunday</option>
                            </select>
                        </div>
                        <hr />
                    </div>
                    <CircularGraph datas={data} />
                </div>
            </div>
        </div>
    );
}

export default CircularGraphHours;
