import React, { useEffect, useState } from "react";
import "./profil.css";
import Cookies from "js-cookie";
import SideMenu from "../SideBar/sideBar";
import { GetUserCompanyAccessCode, GetUserDatas } from "../../GatherUserInformationDB/GatherDatabaseUser";
import BetaAccessModal from "../BetaModal/BetaModal";
import PatchnoteModal from "../BetaModal/PatchnoteModal";
import FacebookSDK from "../facebookSDK/fbSdk";
import TrialModal from "../TrialAccess/TrialAccess";

const AccountPageManager = () => {
  const usernameToken = Cookies.get('authPulseUsername');
  const [userCreationDate, setUserCreaDate] = useState(new Date(new Date().getTime()+ 30 * 24 * 60 * 60 * 1000));
  let userData;

  const createHeader = () => {
    return (
      <div className="title">
        Account:
        <span
          style={{
            background: "linear-gradient(to bottom, transparent 50%, #FFFF00 50%)",
            padding: "2px 4px",
            position: "relative",
            bottom: "-2px",
            display: "inline-block",
          }}
        >
          {usernameToken}
        </span>
      </div>
    );
  };

  const SaveDataToToken = () =>
    {
      // Save Instagram information
      let dataToSave = {};
  
      dataToSave["authInstaMdp"] = document.getElementById("instagram-password").value;
      dataToSave["authInstaUsername"] = document.getElementById("instagram-username").value;
  
      // Save the object as a JSON string in cookies
      Cookies.set("userNetworkData", JSON.stringify(dataToSave));
    }
  
    const LoadDataFromToken = async () =>
      {
          let dataToSave = {};
          // Retrieve the JSON string from cookies and parse it back into an object
          if (Cookies.get("userNetworkData"))
          {
              dataToSave = JSON.parse(Cookies.get("userNetworkData"));
          }
      
          // Populate the input fields with the saved values
          document.getElementById("instagram-password").value = dataToSave.authInstaMdp || "";
          document.getElementById("instagram-username").value = dataToSave.authInstaUsername || "";

          
        userData =  await GetUserDatas();
        document.getElementById("compName").textContent = userData[6];
        document.getElementById("userUsername").textContent = userData[1];
        document.getElementById("userId").textContent = userData[2];

        document.getElementById("userCompCode").textContent = await GetUserCompanyAccessCode();

        setUserCreaDate(new Date(new Date(userData[7]).getTime()+ 30 * 24 * 60 * 60 * 1000));
        
      };
      
    useEffect(() => {
      LoadDataFromToken();
    }, []);
  



  return (
    <div className="main-containerAccManager">
    <TrialModal trialEndDate={userCreationDate}/>
    <BetaAccessModal/>
    <PatchnoteModal/>

      <SideMenu/>

      <div id="menu-container"></div>
      <div id="actuality-container"></div>

      <div className="allcard" id="globalCard">
        <div className="allcard" id="allcard">
          <div className="cardd" id="generalInformation">
            {createHeader()}
          </div>
        </div>

        <div className="allcard general-info-card">
          <div className="horizontal-box">
            <div className="label">Your ID:</div>
            <div className="value" id="userId"></div>
          </div>
          <div className="horizontal-box">
            <div className="label">Your Username:</div>
            <div className="value" id="userUsername"></div>
          </div>
          <div className="horizontal-box">
            <div className="label">Your Company:</div>
            <div className="value" id="compName"></div>
          </div>
          <div className="horizontal-box">
            <div className="label">Company Access Code:</div>
            <div className="value" id="userCompCode"></div>
          </div>
        </div>


        <div className="allcard networks-info">
  <div className="main-title">Networks Information</div>
  <hr className="divider" />
  
  <div className="profile-container">
    <div className="profile-card instagram-card" id="instagram-card">
        <div className="card-header instagram-header">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/1200px-Instagram_icon.png"
            alt="Instagram Logo"
            className="logo"
          />
          <h2 className="network-title">Instagram</h2>
        </div>
        <div className="card-body">
          <form id="instagram-form" className="network-form">
            <input
              type="text"
              id="instagram-username"
              name="username"
              placeholder="Enter Instagram Username"
              className="input-field"
            />
            <input
              type="password"
              id="instagram-password"
              name="password"
              placeholder="Enter Instagram Password"
              className="input-field"
            />
          </form>
        </div>
      </div>
      

  </div>
  
  <button type="button" id="saveButton" className="save-button" onClick={SaveDataToToken}>
    Save
  </button>
</div>

      </div>

      {/* <FacebookSDK/> */}
    </div>
  );
};

export default AccountPageManager;
